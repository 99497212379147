import React, { useState } from 'react';
import { isEmpty } from '../../Utils/utils';
import Skeleton from '../../ReusableComponents/Skeleton';
import CircularProgressBar from '../../ReusableComponents/CircularProgressBar';
import Tooltip from '../../ReusableComponents/Tooltip';

const MonitoringCard = ({
  title,
  text,
  isRiskIcon,
  riskType,
  isPercent,
  percent,
  isPurchasable,
  action,
  isLoading,
  isOutOfView,
  isShowProgress,
  value1,
  value2,
  isMultiple,
  title1,
  text1,
  isLimit,
  textPartOne,
  textPartTwo,
}) => {
  const [toolTipText, setToolTipText] = useState('');
  const [isHovered, setIsHovered] = useState(false);

  const onFocus = (type) => {
    setIsHovered(true);
    setToolTipText(`reveal_${type}`);
  };

  const onBlur = () => {
    setIsHovered(false);
    setToolTipText('');
  };

  return (
    <div className={`health_card ${isLoading ? `health_card_loading` : null}`}>
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          <div className='health_card_title'>
            {isRiskIcon && (
              <>
                {riskType === `high` ? (
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle cx='8' cy='8' r='8' fill='#FEF2F2' />
                    <circle cx='8' cy='8' r='4' fill='#B91C1C' />
                  </svg>
                ) : riskType === `low` ? (
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle cx='8' cy='8' r='8' fill='#F0FDF4' />
                    <circle cx='8' cy='8' r='4' fill='#15803D' />
                  </svg>
                ) : riskType === `medium` ? (
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle cx='8' cy='8' r='8' fill='#FFFBEB' />
                    <circle cx='8' cy='8' r='4' fill='#D97706' />
                  </svg>
                ) : (
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle cx='8' cy='8' r='8' fill='#EDF1F5' />
                    <circle cx='8' cy='8' r='4' fill='#356EB1' />
                  </svg>
                )}
              </>
            )}
            <div className='title_wrapper'>
              <span>{title || `-`}</span>
              {!isOutOfView && isPurchasable && (
                <div className='credit_risk_locked_wrapper'>
                  <Tooltip
                    text={toolTipText}
                    isVisible={isHovered}
                    from='credit_risk_overview'
                    position={riskType === `minimal` ? `left` : `center`}
                  />
                  <span
                    className='credit_risk_locked'
                    onClick={() => action(riskType)}
                    onMouseEnter={() => onFocus(riskType)}
                    onMouseLeave={onBlur}
                  >
                    <svg
                      width='14'
                      height='12'
                      viewBox='0 0 14 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M6.99893 0.75C5.10518 0.75 3.58877 1.6125 2.48486 2.63906C1.38799 3.65625 0.654395 4.875 0.30752 5.71172C0.230176 5.89687 0.230176 6.10312 0.30752 6.28828C0.654395 7.125 1.38799 8.34375 2.48486 9.36094C3.58877 10.3875 5.10518 11.25 6.99893 11.25C8.89268 11.25 10.4091 10.3875 11.513 9.36094C12.6099 8.34141 13.3435 7.125 13.6927 6.28828C13.77 6.10312 13.77 5.89687 13.6927 5.71172C13.3435 4.875 12.6099 3.65625 11.513 2.63906C10.4091 1.6125 8.89268 0.75 6.99893 0.75ZM3.62393 6C3.62393 5.10489 3.97951 4.24645 4.61244 3.61351C5.24538 2.98058 6.10382 2.625 6.99893 2.625C7.89403 2.625 8.75248 2.98058 9.38541 3.61351C10.0183 4.24645 10.3739 5.10489 10.3739 6C10.3739 6.89511 10.0183 7.75355 9.38541 8.38649C8.75248 9.01942 7.89403 9.375 6.99893 9.375C6.10382 9.375 5.24538 9.01942 4.61244 8.38649C3.97951 7.75355 3.62393 6.89511 3.62393 6ZM6.99893 4.5C6.99893 5.32734 6.32627 6 5.49893 6C5.33252 6 5.17314 5.97187 5.02314 5.92266C4.89424 5.88047 4.74424 5.96016 4.74893 6.09609C4.75596 6.25781 4.77939 6.41953 4.82393 6.58125C5.14502 7.78125 6.38018 8.49375 7.58018 8.17266C8.78018 7.85156 9.49268 6.61641 9.17158 5.41641C8.91143 4.44375 8.05127 3.78984 7.09502 3.75C6.95908 3.74531 6.8794 3.89297 6.92158 4.02422C6.9708 4.17422 6.99893 4.33359 6.99893 4.5Z'
                        fill='#334155'
                      />
                    </svg>
                    <svg
                      width='12'
                      height='12'
                      viewBox='0 0 12 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      className='dollar_lock_icon'
                    >
                      <rect width='12' height='12' rx='6' fill='#15803D' />
                      <path
                        d='M6.01272 1.99854C6.28928 1.99854 6.51272 2.22197 6.51272 2.49854V3.05635C6.53772 3.05947 6.56116 3.0626 6.58616 3.06729C6.59241 3.06885 6.59709 3.06885 6.60334 3.07041L7.35334 3.20791C7.62522 3.25791 7.80491 3.51885 7.75491 3.78916C7.70491 4.05947 7.44397 4.24072 7.17366 4.19072L6.43147 4.05479C5.94241 3.98291 5.51116 4.03135 5.20803 4.15166C4.90491 4.27197 4.78303 4.4376 4.75491 4.59072C4.72366 4.75791 4.74709 4.85166 4.77366 4.90947C4.80178 4.97041 4.85959 5.03916 4.97366 5.11572C5.22834 5.28291 5.61897 5.39229 6.12522 5.52666L6.17053 5.53916C6.61741 5.65791 7.16428 5.80166 7.57053 6.06729C7.79241 6.2126 8.00178 6.40947 8.13147 6.68447C8.26428 6.96416 8.29241 7.27666 8.23147 7.60947C8.12366 8.20322 7.71428 8.6001 7.20647 8.80791C6.99241 8.89541 6.75959 8.95166 6.51272 8.97978V9.50166C6.51272 9.77822 6.28928 10.0017 6.01272 10.0017C5.73616 10.0017 5.51272 9.77822 5.51272 9.50166V8.95635C5.50647 8.95478 5.49866 8.95479 5.49241 8.95322H5.48928C5.10803 8.89385 4.48147 8.72978 4.05959 8.54228C3.80803 8.42978 3.69397 8.13447 3.80647 7.88291C3.91897 7.63135 4.21428 7.51728 4.46584 7.62979C4.79241 7.7751 5.32991 7.91885 5.64084 7.96729C6.13928 8.04072 6.55022 7.99854 6.82834 7.88447C7.09241 7.77666 7.21272 7.62041 7.24709 7.43291C7.27678 7.26728 7.25334 7.17197 7.22678 7.11416C7.19709 7.05166 7.13928 6.98291 7.02366 6.90635C6.76741 6.73916 6.37522 6.62979 5.86741 6.49541L5.82366 6.48447C5.37834 6.3626 4.83147 6.21729 4.42522 5.95166C4.20334 5.80635 3.99553 5.60791 3.86584 5.33291C3.73459 5.05322 3.70803 4.74072 3.77053 4.40791C3.88303 3.81104 4.32991 3.42354 4.83772 3.22197C5.04553 3.13916 5.27366 3.08291 5.51272 3.0501V2.49854C5.51272 2.22197 5.73616 1.99854 6.01272 1.99854Z'
                        fill='white'
                      />
                    </svg>
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className='health_card_text'>
            {isMultiple ? (
              <div className='health_multiple_text'>
                <span className='health_text'>
                  {isLimit ? (
                    <span className='health_text_parts'>
                      <span>{textPartOne}</span>
                      {` `}
                      <span>{textPartTwo}</span>
                    </span>
                  ) : (
                    <span>{text}</span>
                  )}
                </span>
                <span className='health_text_one'>
                  <span>{title1}: </span>
                  <span>{text1}</span>
                </span>
              </div>
            ) : (
              <span className='health_text'>
                {isShowProgress && (
                  <CircularProgressBar
                    progress={(Number(value1) / Number(value2)) * 100}
                  />
                )}
                {isLimit ? (
                  <span className='health_text_parts'>
                    <span>{textPartOne}</span>
                    {` `}
                    <span>{textPartTwo}</span>
                  </span>
                ) : (
                  <span>{text}</span>
                )}
              </span>
            )}

            {/* {isPercent && !isEmpty(percent) && (
              <span className='health_percentage_wrapper'>
                <span className='percent_icon'>
                  {percent > 0 ? (
                    <svg
                      width='15'
                      height='8'
                      viewBox='0 0 15 8'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M10.1998 1.60012C9.75726 1.60012 9.39973 1.2426 9.39973 0.800062C9.39973 0.357528 9.75726 0 10.1998 0H14.2001C14.6426 0 15.0002 0.357528 15.0002 0.800062V4.80037C15.0002 5.24291 14.6426 5.60044 14.2001 5.60044C13.7576 5.60044 13.4 5.24291 13.4 4.80037V2.73271L9.16471 6.96554C8.85219 7.27807 8.34465 7.27807 8.03212 6.96554L5.39942 4.33284L1.96415 7.76561C1.65163 8.07813 1.14409 8.07813 0.831561 7.76561C0.519037 7.45308 0.519037 6.94554 0.831561 6.63302L4.83187 2.63271C5.1444 2.32018 5.65194 2.32018 5.96446 2.63271L8.59967 5.26791L12.2675 1.60012H10.1998Z'
                        fill='#15803D'
                      />
                    </svg>
                  ) : (
                    <svg
                      width='15'
                      height='8'
                      viewBox='0 0 15 8'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M10.1998 6.39987C9.75726 6.39987 9.39973 6.7574 9.39973 7.19994C9.39973 7.64247 9.75726 8 10.1998 8H14.2001C14.6426 8 15.0002 7.64247 15.0002 7.19994V3.19962C15.0002 2.75709 14.6426 2.39956 14.2001 2.39956C13.7576 2.39956 13.4 2.75709 13.4 3.19962V5.26729L9.16471 1.03446C8.85219 0.721931 8.34465 0.721931 8.03212 1.03446L5.39942 3.66716L1.96415 0.234393C1.65163 -0.0781311 1.14409 -0.0781311 0.831561 0.234393C0.519037 0.546918 0.519037 1.05446 0.831561 1.36698L4.83187 5.36729C5.1444 5.67982 5.65194 5.67982 5.96446 5.36729L8.59967 2.73209L12.2675 6.39987H10.1998Z'
                        fill='#15803D'
                      />
                    </svg>
                  )}
                </span>
                <span className='percent_text'>{Math.abs(percent)}%</span>
              </span>
            )} */}
            {isOutOfView && isPurchasable && (
              <div className='credit_risk_locked_wrapper'>
                <span
                  className='credit_risk_locked'
                  onClick={() => action(riskType)}
                >
                  <svg
                    width='14'
                    height='12'
                    viewBox='0 0 14 12'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6.99893 0.75C5.10518 0.75 3.58877 1.6125 2.48486 2.63906C1.38799 3.65625 0.654395 4.875 0.30752 5.71172C0.230176 5.89687 0.230176 6.10312 0.30752 6.28828C0.654395 7.125 1.38799 8.34375 2.48486 9.36094C3.58877 10.3875 5.10518 11.25 6.99893 11.25C8.89268 11.25 10.4091 10.3875 11.513 9.36094C12.6099 8.34141 13.3435 7.125 13.6927 6.28828C13.77 6.10312 13.77 5.89687 13.6927 5.71172C13.3435 4.875 12.6099 3.65625 11.513 2.63906C10.4091 1.6125 8.89268 0.75 6.99893 0.75ZM3.62393 6C3.62393 5.10489 3.97951 4.24645 4.61244 3.61351C5.24538 2.98058 6.10382 2.625 6.99893 2.625C7.89403 2.625 8.75248 2.98058 9.38541 3.61351C10.0183 4.24645 10.3739 5.10489 10.3739 6C10.3739 6.89511 10.0183 7.75355 9.38541 8.38649C8.75248 9.01942 7.89403 9.375 6.99893 9.375C6.10382 9.375 5.24538 9.01942 4.61244 8.38649C3.97951 7.75355 3.62393 6.89511 3.62393 6ZM6.99893 4.5C6.99893 5.32734 6.32627 6 5.49893 6C5.33252 6 5.17314 5.97187 5.02314 5.92266C4.89424 5.88047 4.74424 5.96016 4.74893 6.09609C4.75596 6.25781 4.77939 6.41953 4.82393 6.58125C5.14502 7.78125 6.38018 8.49375 7.58018 8.17266C8.78018 7.85156 9.49268 6.61641 9.17158 5.41641C8.91143 4.44375 8.05127 3.78984 7.09502 3.75C6.95908 3.74531 6.8794 3.89297 6.92158 4.02422C6.9708 4.17422 6.99893 4.33359 6.99893 4.5Z'
                      fill='#334155'
                    />
                  </svg>
                  <svg
                    width='12'
                    height='12'
                    viewBox='0 0 12 12'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='dollar_lock_icon'
                  >
                    <rect width='12' height='12' rx='6' fill='#15803D' />
                    <path
                      d='M6.01272 1.99854C6.28928 1.99854 6.51272 2.22197 6.51272 2.49854V3.05635C6.53772 3.05947 6.56116 3.0626 6.58616 3.06729C6.59241 3.06885 6.59709 3.06885 6.60334 3.07041L7.35334 3.20791C7.62522 3.25791 7.80491 3.51885 7.75491 3.78916C7.70491 4.05947 7.44397 4.24072 7.17366 4.19072L6.43147 4.05479C5.94241 3.98291 5.51116 4.03135 5.20803 4.15166C4.90491 4.27197 4.78303 4.4376 4.75491 4.59072C4.72366 4.75791 4.74709 4.85166 4.77366 4.90947C4.80178 4.97041 4.85959 5.03916 4.97366 5.11572C5.22834 5.28291 5.61897 5.39229 6.12522 5.52666L6.17053 5.53916C6.61741 5.65791 7.16428 5.80166 7.57053 6.06729C7.79241 6.2126 8.00178 6.40947 8.13147 6.68447C8.26428 6.96416 8.29241 7.27666 8.23147 7.60947C8.12366 8.20322 7.71428 8.6001 7.20647 8.80791C6.99241 8.89541 6.75959 8.95166 6.51272 8.97978V9.50166C6.51272 9.77822 6.28928 10.0017 6.01272 10.0017C5.73616 10.0017 5.51272 9.77822 5.51272 9.50166V8.95635C5.50647 8.95478 5.49866 8.95479 5.49241 8.95322H5.48928C5.10803 8.89385 4.48147 8.72978 4.05959 8.54228C3.80803 8.42978 3.69397 8.13447 3.80647 7.88291C3.91897 7.63135 4.21428 7.51728 4.46584 7.62979C4.79241 7.7751 5.32991 7.91885 5.64084 7.96729C6.13928 8.04072 6.55022 7.99854 6.82834 7.88447C7.09241 7.77666 7.21272 7.62041 7.24709 7.43291C7.27678 7.26728 7.25334 7.17197 7.22678 7.11416C7.19709 7.05166 7.13928 6.98291 7.02366 6.90635C6.76741 6.73916 6.37522 6.62979 5.86741 6.49541L5.82366 6.48447C5.37834 6.3626 4.83147 6.21729 4.42522 5.95166C4.20334 5.80635 3.99553 5.60791 3.86584 5.33291C3.73459 5.05322 3.70803 4.74072 3.77053 4.40791C3.88303 3.81104 4.32991 3.42354 4.83772 3.22197C5.04553 3.13916 5.27366 3.08291 5.51272 3.0501V2.49854C5.51272 2.22197 5.73616 1.99854 6.01272 1.99854Z'
                      fill='white'
                    />
                  </svg>
                </span>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MonitoringCard;
