import Router from './Router';
import Button from './NewComponents/Button/Button';
import { CrossIcon, EmailIcon, WhitePlusIcon } from './icons/icons';
import Input from './NewComponents/InputGroup/Input';
import DatePicker from './ReusableComponents/Datepicker/DatePicker';
import Datepicker from './NewComponents/Datepicker/Datepicker';
import Checkbox from './NewComponents/Checkbox/Checkbox';
import Select from './NewComponents/SelectGroup/Select';
const App = () => {
  return (
    <div className='App'>
      <Router />
      {/* <Datepicker /> */}
      {/* // uncomment this block to test select */}
      {/* <div style={{ display: 'flex', alignItems: 'center', gap: '5rem' }}>
        <Select isSearch={true} />

        <Select isSearch={false} />
      </div> */}
      {/* <Checkbox /> */}
      {/* <Input placeholder='Enter Your Name' isDisabled={true} />
      <Input placeholder='Enter Your Error' isError={true} /> */}
      {/* <DatePicker /> */}
      {/* <Input placeholder='Enter Your Name' />
      <Input placeholder='Enter Your Name' isDisabled={true} />
      <Input placeholder='Enter Your Name' isError={true} />
      <Input
        placeholder='Enter Your Name'
        isIcon={true}
        isIconLeft={true}
        iconLeft={EmailIcon}
      />

      <Input
        placeholder='Enter Your Name'
        isIcon={true}
        isIconRight={true}
        iconRight={CrossIcon}
      />
      <Input
        placeholder='Enter Your Name'
        isIcon={true}
        isIconLeft={true}
        iconLeft={EmailIcon}
        isIconRight={true}
        iconRight={CrossIcon}
      /> */}
      {/* <Button
        text={`button text`}
        isIconOnly={true}
        isIcon={true}
        isIconLeft={true}
        icon={WhitePlusIcon}
      />
      <Button
        text={`button text`}
        isIcon={true}
        isIconLeft={true}
        icon={WhitePlusIcon}
        size='small'
      />
      <Button
        text={`button text`}
        isIcon={true}
        isIconLeft={false}
        icon={WhitePlusIcon}
      />
      <Button isLoading={true} text='loading button' />
      <Button isIconOnly={true} icon={WhitePlusIcon} />
      <Button variant='destructive' text={`delete`} isLoading={true} />
      <Button variant='destructive' size='small' text={`delete`} />
      <Button variant='outlined' text='some very long text here' />
      <Button variant='outlined' size='small' />
      <Button variant='subtle' text='something' isLoading={true} />
      <Button variant='subtle' size='small' />
      <Button variant='ghost' text='ghost' isLoading={true} />
      <Button variant='ghost' size='small' />
      <Button variant='link' />
      <Button variant='link' size='small' /> */}
    </div>
  );
};

export default App;
