export const isEmpty = (value) => {
  if (
    !value ||
    value === undefined ||
    value === null ||
    value?.toString()?.trim()?.length === 0
  )
    return true;
  else return false;
};

export const isValidDate = (dateString) => {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
};

export const isEmailValid = (email) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (emailRegex.test(email)) return true;
  else return false;
};

export const isNumber = (number) => {
  const regex = /^[0-9\b]+$/;
  if (regex.test(number) || number === '') return true;
  else return false;
};

export const isAlphabet = (value) => {
  const regex = /^[a-z]+$/i;
  if (regex.test(value) || value === '') return true;
  else return false;
};

export const isPostalCodeValid = (value) => {
  const regex = /^([A-z][0-9][A-z][0-9][A-z][0-9]+)$/gm;
  if (regex.test(value) || value === '') return true;
  else return false;
};
export const isPhoneValid = (value) => {
  if (value?.length === 14) {
    return true;
  } else return false;
};

export const isZip = (value) => {
  if (value?.length === 5) {
    return true;
  } else return false;
};

export const isCurrency = (value) => {
  const regex = /^-?\$?(\d{1,3})(,\d{3})*(\.\d{0,2})?$/;
  if (regex.test(value) || value === '') return true;
  else return false;
};

export const createAddress = (
  civicNumber,
  city,
  street,
  suite,
  province,
  postalCode
) => {
  const address = `${civicNumber && civicNumber + ` `}${city && city + ` `}${
    street && street + ` `
  }${suite && suite + ` `}${province && province + ` `}${postalCode}`;
  const updatedAddress = address?.split(` `)?.join(`, `);
  return updatedAddress;
};

export const formatDate = (date) => {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const convertToSquareBracketNotation = (obj, prefix = '') => {
  const result = {};

  for (const [key, value] of Object.entries(obj)) {
    const newKey = prefix ? `${prefix}[${key}]` : key;

    if (typeof value === 'object' && value !== null) {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          Object.assign(
            result,
            convertToSquareBracketNotation({ [index]: item }, `${newKey}`)
          );
        });
      } else {
        Object.assign(result, convertToSquareBracketNotation(value, newKey));
      }
    } else {
      result[newKey] = value;
    }
  }
  return result;
};

export const camelToSnake = (camelStr) => {
  return camelStr.replace(/([a-z0-9])([A-Z])/g, '$1_$2').toLowerCase();
};

export const money = (value) => {
  if (isEmpty(value)) return noMoney();
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);
};

export const CADmoney = (value) => {
  if (isEmpty(value)) return noMoney();
  return new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
  }).format(value);
};

export const noMoney = () => {
  return `$` + `0.00`;
};

export const cleanMoney = (value) => {
  if (value === undefined || value === null || value === '') return 0; // Handle undefined or empty values

  // Convert value to string and trim spaces
  let cleanedValue = value.toString().trim();

  // Handle negative value in parentheses, even with extra characters like `$` at the end
  const parenthesisMatch = cleanedValue.match(/^\(([^)]+)\)/);
  if (parenthesisMatch) {
    cleanedValue = '-' + parenthesisMatch[1]; // Extract the number inside parentheses and make it negative
  }

  // Remove any non-numeric characters except the decimal point and minus sign
  cleanedValue = cleanedValue.replace(/[^0-9.,-]+/g, '');

  // Normalize commas to dots if commas are used as decimal separators
  if (cleanedValue.includes(',') && !cleanedValue.includes('.')) {
    cleanedValue = cleanedValue.replace(/,/g, '.');
  }

  // Remove thousand separators (commas between numbers)
  cleanedValue = cleanedValue.replace(/(\d),(\d)/g, '$1$2');

  // Convert the cleaned value into a float
  let numberValue = parseFloat(cleanedValue);

  // Handle NaN result (non-numeric input after cleaning)
  if (isNaN(numberValue)) return 0;

  // Return the number with two decimal places
  return parseFloat(numberValue.toFixed(2));
};

export const normalizeCurrency = (currency) => {
  // Ensure currency is a string and convert it to lowercase
  if (['us', 'usd'].includes((currency || '').toLowerCase())) {
    return 'USD'; // Return USD for any variation of 'US' or 'USD'
  }
  return 'CAD'; // Default to CAD for any other value
};

/**
 * Removes diacritics (accents) from a string by normalizing it (NFD)
 * and removing the diacritic marks.
 *
 * @param {string} str
 * @returns {string}
 */
const removeDiacritics = (str) => {
  return str
    .normalize('NFD') // separates base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, ''); // removes the diacritical marks
};

/**
 * Normalizes the input string:
 *   - converts to lowercase
 *   - removes diacritics (accents)
 *   - removes spaces, periods, hyphens, and apostrophes
 *
 * This makes it easier to match user input that might include punctuation
 * or accented characters.
 *
 * @param {string} str
 * @returns {string} The "cleaned" version of the string (lowercase, no accents, no punctuation).
 */
const normalizeInput = (str) => {
  return (
    removeDiacritics(str.toLowerCase())
      // remove spaces, periods, hyphens, apostrophes
      .replace(/[.\-'\s]/g, '')
  );
};

/**
 * A dictionary of Canadian provinces and territories.
 * Each entry maps a 2-letter code (key) to an array of possible
 * "cleaned" variations (value).
 *
 * For instance, "N.B.", "N-B", "n b", etc. all become "nb" and match
 * the "NB" key here if "nb" is listed as a variation.
 */
const CAN_PROVINCE_MAP = {
  // PROVINCES
  AB: ['ab', 'alberta'],
  BC: ['bc', 'cb', 'britishcolumbia', 'colombiebritannique'],
  MB: ['mb', 'manitoba'],
  NB: ['nb', 'newbrunswick', 'nouveaubrunswick'],
  NL: [
    'nl',
    'nf',
    'newfoundlandandlabrador',
    'newfoundland',
    'labrador',
    'terreneuveetlabrador',
    'terreneuve',
  ],
  NS: ['ns', 'novascotia', 'nouvelleecosse'],
  ON: ['on', 'ont', 'ontario'],
  PE: ['pe', 'pei', 'princeedwardisland', 'ileduprinceedouard'],
  QC: ['qc', 'que', 'queb', 'quebec'],
  SK: ['sk', 'sask', 'saskatchewan'],
  // TERRITORIES
  NT: [
    'nt',
    'nwt',
    'northwestterritories',
    'northwestterritorie',
    'territoiresdunordouest',
  ],
  NU: ['nu', 'nunavut'],
  YT: ['yt', 'yukon', 'yukonterritory', 'territoireduyukon'],
};

/**
 * Normalizes a Canadian province/territory name (in English, French, with or without punctuation)
 * to its two-letter code (e.g., "QC" for Quebec).
 *
 * @param {string} inputProvince - The user-provided province/territory input.
 * @returns {string} The 2-letter code for the province/territory.
 * @throws {Error} If no match is found in the dictionary.
 */
export const normalizeProvince = (inputProvince) => {
  if (!inputProvince) {
    return '';
  }

  // Clean the user input
  const cleaned = normalizeInput(inputProvince);

  // Look up each code and its variations
  for (const [code, variations] of Object.entries(CAN_PROVINCE_MAP)) {
    if (variations.includes(cleaned)) {
      return code;
    }
  }

  // If nothing matched, throw an error
  return '';
};

export const monthOptions = [
  {
    index: 0,
    name: 'January',
    value: {
      en: 'January',
      fr: 'Janvier',
    },
  },
  {
    index: 1,
    name: 'February',
    value: {
      en: 'February',
      fr: 'Février',
    },
  },
  {
    index: 2,
    name: 'March',
    value: {
      en: 'March',
      fr: 'Mars',
    },
  },
  {
    index: 3,
    name: 'April',
    value: {
      en: 'April',
      fr: 'Avril',
    },
  },
  {
    index: 4,
    name: 'May',
    value: {
      en: 'May',
      fr: 'Mai',
    },
  },
  {
    index: 5,
    name: 'June',
    value: {
      en: 'June',
      fr: 'Juin',
    },
  },
  {
    index: 6,
    name: 'July',
    value: {
      en: 'July',
      fr: 'Juillet',
    },
  },
  {
    index: 7,
    name: 'August',
    value: {
      en: 'August',
      fr: 'Août',
    },
  },
  {
    index: 8,
    name: 'September',
    value: {
      en: 'September',
      fr: 'Septembre',
    },
  },
  {
    index: 9,
    name: 'October',
    value: {
      en: 'October',
      fr: 'Octobre',
    },
  },
  {
    index: 10,
    name: 'November',
    value: {
      en: 'November',
      fr: 'Novembre',
    },
  },
  {
    index: 11,
    name: 'December',
    value: {
      en: 'December',
      fr: 'Décembre',
    },
  },
];

export const years = [
  1900, 1901, 1902, 1903, 1904, 1905, 1906, 1907, 1908, 1909, 1910, 1911, 1912,
  1913, 1914, 1915, 1916, 1917, 1918, 1919, 1920, 1921, 1922, 1923, 1924, 1925,
  1926, 1927, 1928, 1929, 1930, 1931, 1932, 1933, 1934, 1935, 1936, 1937, 1938,
  1939, 1940, 1941, 1942, 1943, 1944, 1945, 1946, 1947, 1948, 1949, 1950, 1951,
  1952, 1953, 1954, 1955, 1956, 1957, 1958, 1959, 1960, 1961, 1962, 1963, 1964,
  1965, 1966, 1967, 1968, 1969, 1970, 1971, 1972, 1973, 1974, 1975, 1976, 1977,
  1978, 1979, 1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990,
  1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003,
  2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016,
  2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026,
];
