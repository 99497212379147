import React, { useEffect, useRef, useState } from 'react';
import AllianceWhite from '../images/alliance_white.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage, setNavigation } from '../slices/commonSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AiOutlineDown } from 'react-icons/ai';
import { clear, set } from 'idb-keyval';
import { logout } from '../slices/authSlice';
import i18n from '../Utils/i18n';
import { resetAdmin } from '../slices/adminClientSlice';

const Header = ({ clientNavigation, groupNavigation }) => {
  const topBarRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const containerRef = useRef();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { navigationItem, isEN } = useSelector((state) => state.common);
  const { user } = useSelector((state) => state.auth);
  const { adminInformation } = useSelector((state) => state.adminClient);
  const { accountDetails } = useSelector((state) => state.account);

  const [navItem, setNavItem] = useState('');
  const [isDropdown, setIsDropdown] = useState(false);

  const clickedOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener(`click`, clickedOutside, true);
    return () => {
      document.removeEventListener(`click`, clickedOutside, true);
    };
  });

  useEffect(() => {
    const path = pathname?.split(`/`)?.[1];
    const route = path || navItem;
    dispatch(setNavigation(route));
  }, [navItem, pathname, dispatch]);

  const onNavigation = (navItem) => {
    setNavItem(navItem);
    navigate(navItem);
  };

  const creditNavs = [`application-details`, `credit-applications`];

  const onAccountDropdown = () => setIsDropdown((prev) => !prev);

  const onLogout = () => {
    clear();
    window.location.href = `https://portail.alliancecredit.ca/`;
    dispatch(logout());
    dispatch(resetAdmin());
    setIsDropdown(false);
  };

  const onLanguageSwitch = async (selectedLanguage) => {
    await set(`language`, selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    dispatch(setLanguage(selectedLanguage));
  };

  const onDropdownNavigation = (path) => {
    navigate(`/${path}`);
    setIsDropdown(false);
  };

  return (
    <>
      <div className='topbar_wrapper' ref={topBarRef} tabIndex='1'>
        <div className='navigation_wrapper'>
          <div className='navigation_item'>
            <img src={AllianceWhite} alt='logo' className='navbar_logo' />
          </div>
          {/* <div
            className={`navigation_item ${
              [`credit-reports`, `esc-data`]?.includes(navigationItem)
                ? `selected`
                : null
            }`}
            onClick={() => onNavigation(`credit-reports`)}
          >
            My Reports
          </div>
          <div className="navigation_item">{`${t(`history`)}`}</div>
          <div className="navigation_item">{`${t(`order_report`)}`}</div>
          <div
            className={`navigation_item ${
              navigationItem === `database-reports` ? `selected` : null
            }`}
            onClick={() => onNavigation(`database-reports`)}
          >{`${t(`database_reports`)}`}</div>
          <div
            className={`navigation_item ${
              navigationItem === `legal-watchlist` ? `selected` : null
            }`}
            onClick={() => onNavigation(`legal-watchlist`)}
          >{`${t(`legal_watchlist`)}`}</div>
          <div
            className={`navigation_item ${
              groupNavigation?.includes(navigationItem) ? `selected` : null
            }`}
            onClick={() => onNavigation(`groups`)}
          >{`${t(`groups`)}`}</div>
          <div
            className={`navigation_item ${
              clientNavigation?.includes(navigationItem) ? `selected` : null
            }`}
            onClick={() => onNavigation(`clients`)}
          >
            {`${t(`clients`)}`}
          </div>
          <div
            className={`navigation_item ${
              creditNavs?.includes(navigationItem) ? `selected` : null
            }`}
            onClick={() => onNavigation(`credit-applications`)}
          >{`${t(`credit_applications`)}`}</div> */}
        </div>
        <div className='language_switch'>
          <svg
            width='20'
            height='16'
            viewBox='0 0 20 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M0 4C0 2.89688 0.896875 2 2 2H8H9.5H10H18C19.1031 2 20 2.89688 20 4V12C20 13.1031 19.1031 14 18 14H10H9.5H8H2C0.896875 14 0 13.1031 0 12V4ZM10 4V12H18V4H10ZM5.57188 5.49687C5.47188 5.27187 5.24687 5.125 5 5.125C4.75313 5.125 4.52812 5.27187 4.42812 5.49687L2.42812 9.99687C2.2875 10.3125 2.43125 10.6812 2.74688 10.8219C3.0625 10.9625 3.43125 10.8188 3.57188 10.5031L3.85 9.875H6.15L6.42812 10.5031C6.56875 10.8188 6.9375 10.9594 7.25313 10.8219C7.56875 10.6844 7.70937 10.3125 7.57188 9.99687L5.57188 5.49687ZM5 7.2875L5.59375 8.625H4.40625L5 7.2875ZM14 5.125C14.3438 5.125 14.625 5.40625 14.625 5.75V5.875H16H16.5C16.8438 5.875 17.125 6.15625 17.125 6.5C17.125 6.84375 16.8438 7.125 16.5 7.125H16.4375L16.3875 7.26562C16.1094 8.02812 15.6875 8.72187 15.15 9.30937C15.1781 9.32812 15.2063 9.34375 15.2344 9.35938L15.825 9.7125C16.1219 9.89062 16.2156 10.275 16.0406 10.5688C15.8656 10.8625 15.4781 10.9594 15.1844 10.7844L14.5938 10.4312C14.4531 10.3469 14.3187 10.2594 14.1844 10.1656C13.8531 10.4 13.5 10.6031 13.1219 10.7719L13.0094 10.8219C12.6937 10.9625 12.325 10.8188 12.1844 10.5031C12.0437 10.1875 12.1875 9.81875 12.5031 9.67813L12.6156 9.62813C12.8156 9.5375 13.0094 9.4375 13.1938 9.32187L12.8125 8.94063C12.5688 8.69688 12.5688 8.3 12.8125 8.05625C13.0562 7.8125 13.4531 7.8125 13.6969 8.05625L14.1531 8.5125L14.1687 8.52812C14.5562 8.11875 14.8719 7.64375 15.1 7.12187H14H11.75C11.4062 7.12187 11.125 6.84062 11.125 6.49687C11.125 6.15312 11.4062 5.87187 11.75 5.87187H13.375V5.74687C13.375 5.40312 13.6562 5.12187 14 5.12187V5.125Z'
              fill='white'
            />
          </svg>
          <span
            className='language_name'
            onClick={() => onLanguageSwitch(isEN ? `fr` : `en`)}
          >
            {isEN ? `FR` : `EN` }
          </span>
        </div>
        <div className='account_details'>
          <div className='account_information' onClick={onAccountDropdown}>
            <span>{`${t(`hello`)}`},</span> &nbsp;
            <span className='loggedin_user_name'>
              {adminInformation?.full_name ??
                accountDetails?.full_name ??
                user?.full_name}
            </span>
            <AiOutlineDown className='account_icon' />
          </div>
        </div>
        <div
          className={`account_popup_wrapper ${
            isDropdown ? `dropdown_visible` : `dropdown_invisible`
          }`}
          ref={containerRef}
        >
          {/* <div
            className='account_nav'
            onClick={() => onDropdownNavigation(`my-account`)}
          >
            <svg
              width='14'
              height='17'
              viewBox='0 0 14 17'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M7 8.5C8.06087 8.5 9.07828 8.07857 9.82843 7.32843C10.5786 6.57828 11 5.56087 11 4.5C11 3.43913 10.5786 2.42172 9.82843 1.67157C9.07828 0.921427 8.06087 0.5 7 0.5C5.93913 0.5 4.92172 0.921427 4.17157 1.67157C3.42143 2.42172 3 3.43913 3 4.5C3 5.56087 3.42143 6.57828 4.17157 7.32843C4.92172 8.07857 5.93913 8.5 7 8.5ZM5.57188 10C2.49375 10 0 12.4937 0 15.5719C0 16.0844 0.415625 16.5 0.928125 16.5H13.0719C13.5844 16.5 14 16.0844 14 15.5719C14 12.4937 11.5063 10 8.42813 10H5.57188Z'
                fill='#64748B'
              />
            </svg>
            <span>{`${t(`my_account`)}`}</span>
          </div> */}
          {/* <div
            className='account_nav'
            onClick={() => onDropdownNavigation(`all-transactions`)}
          >
            <svg
              width='12'
              height='17'
              viewBox='0 0 12 17'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M2 0.5C0.896875 0.5 0 1.39688 0 2.5V14.5C0 15.6031 0.896875 16.5 2 16.5H10C11.1031 16.5 12 15.6031 12 14.5V5.5H8C7.44688 5.5 7 5.05312 7 4.5V0.5H2ZM8 0.5V4.5H12L8 0.5ZM3.5 8.5H8.5C8.775 8.5 9 8.725 9 9C9 9.275 8.775 9.5 8.5 9.5H3.5C3.225 9.5 3 9.275 3 9C3 8.725 3.225 8.5 3.5 8.5ZM3.5 10.5H8.5C8.775 10.5 9 10.725 9 11C9 11.275 8.775 11.5 8.5 11.5H3.5C3.225 11.5 3 11.275 3 11C3 10.725 3.225 10.5 3.5 10.5ZM3.5 12.5H8.5C8.775 12.5 9 12.725 9 13C9 13.275 8.775 13.5 8.5 13.5H3.5C3.225 13.5 3 13.275 3 13C3 12.725 3.225 12.5 3.5 12.5Z'
                fill='#64748B'
              />
            </svg>
            <span>{`${t(`transactions`)}`}</span>
          </div> */}
          {/* <div className='account_nav'>
            <svg
              width='12'
              height='17'
              viewBox='0 0 12 17'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M2 0.5C0.896875 0.5 0 1.39688 0 2.5V14.5C0 15.6031 0.896875 16.5 2 16.5H10C11.1031 16.5 12 15.6031 12 14.5V5.5H8C7.44688 5.5 7 5.05312 7 4.5V0.5H2ZM8 0.5V4.5H12L8 0.5ZM6.75 13.25C6.75 13.6656 6.41563 14 6 14C5.58437 14 5.25 13.6656 5.25 13.25V10.0594L4.28125 11.0281C3.9875 11.3219 3.5125 11.3219 3.22187 11.0281C2.93125 10.7344 2.92812 10.2594 3.22187 9.96875L5.47188 7.71875C5.76562 7.425 6.24062 7.425 6.53125 7.71875L8.78125 9.96875C9.075 10.2625 9.075 10.7375 8.78125 11.0281C8.4875 11.3187 8.0125 11.3219 7.72188 11.0281L6.75313 10.0594V13.25H6.75Z'
                fill='#64748B'
              />
            </svg>

            <span>{`${t(`legal_upload`)}`}</span>
          </div> */}

          <div className='account_nav account_logout' onClick={onLogout}>
            <svg
              width='16'
              height='17'
              viewBox='0 0 16 17'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M15.7063 9.20625C16.0969 8.81563 16.0969 8.18125 15.7063 7.79063L11.7063 3.79063C11.3156 3.4 10.6812 3.4 10.2906 3.79063C9.9 4.18125 9.9 4.81563 10.2906 5.20625L12.5844 7.5H6C5.44688 7.5 5 7.94688 5 8.5C5 9.05312 5.44688 9.5 6 9.5H12.5844L10.2906 11.7937C9.9 12.1844 9.9 12.8188 10.2906 13.2094C10.6812 13.6 11.3156 13.6 11.7063 13.2094L15.7063 9.20938V9.20625ZM5 3.5C5.55312 3.5 6 3.05313 6 2.5C6 1.94687 5.55312 1.5 5 1.5H3C1.34375 1.5 0 2.84375 0 4.5V12.5C0 14.1562 1.34375 15.5 3 15.5H5C5.55312 15.5 6 15.0531 6 14.5C6 13.9469 5.55312 13.5 5 13.5H3C2.44688 13.5 2 13.0531 2 12.5V4.5C2 3.94688 2.44688 3.5 3 3.5H5Z'
                fill='white'
              />
            </svg>

            <span>{`${t(`logout`)}`}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
