import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../ReusableComponents/Button';
import { ReactSpreadsheetImport } from 'react-spreadsheet-import';
import SideDrawer from '../../ReusableComponents/SideDrawer';
import UploadAgingAddClient from './UploadAgingAddClient';
import AddClient from './AddClient';
import { useDispatch, useSelector } from 'react-redux';
import ToastMessage from '../../ReusableComponents/ToastMessage';
import {
  getAlerts,
  getClientInformation,
  getDashboardDetails,
  getVerifiedCompanies,
  resetHealthMonitoringStore,
  uploadAgingFile,
  getClientDetails,
} from '../../slices/creditRiskSlice';
import {
  convertToSquareBracketNotation,
  formatDate,
  isEmpty,
  isValidDate,
  money,
  cleanMoney,
  normalizeCurrency,
  normalizeProvince,
} from '../../Utils/utils';
import MonitoringSummary from './MonitoringSummary';
import ClientList from './ClientList';
import { get, set } from 'idb-keyval';
import Subscription from './Subscription';
import Select from '../../ReusableComponents/Select';
import HealthMonitoringIcon from './HealthMonitoringIcon';
import Container from '../../ReusableComponents/Container';

const CreditRiskManagement = ({ isEN }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const addClientRef = useRef();

  const navigation = {
    navOne: `${t(`upload`)}`,
    navTwo: `${t(`add_client`)}`,
    className: 'credit_risk_navs',
  };

  const {
    apiMessage,
    isClientAdding,
    isClientAdded,
    isAlertDeleted,
    isColumnUpdateError,
    isRiskDiscovered,
    isClientDeleted,
    isClientInformationFetching,
    isClientInformationFetched,
    clientInformation,
    isDiscoverError,
    isClientUpdating,
    isClientUpdated,
    isClientInformationFetchError,
    isClientDetailsFetching,
    isReportOrdered,
    isReportOrderError,
    isTypeDiscovered,
    isRiskDiscovering,
    isAgingFileUploading,
    isAgingFileUploaded,
    isAgingFileUploadError,
    isVerifiedCompaniesFetched,
    isVerifiedCompaniesFetching,
    verifiedCompanies,
    isClientDetailsFetched,
    clientDetails,
    isSummaryFetched,
    isSummaryFetching,
    summary,
    isAlertsFetching,
    isAlertsFetched,
    alerts,
    isColumnsUpdated,
    isClientDetailsFetchError,
    isRefreshing,
    isRefreshed,
    clientsUnderProcess,
  } = useSelector((state) => state.creditRisk);

  const [companyId, setCompanyId] = useState('');
  const [isAdd, setIsAdd] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [file, setFile] = useState('');
  const [isAddClient, setIsAddClient] = useState(false);
  const [isUploadAging, setIsUploadAging] = useState(false);
  const [clientData, setClientData] = useState({
    upto: {
      month: new Date()?.getMonth() - 1,
      year: new Date()?.getFullYear(),
    },
  });
  const [companyErrorMessage, setCompanyErrorMessage] = useState('');
  const [isEditClient, setIsEditClient] = useState(false);
  const [isRiskFilter, setIsRiskFilter] = useState(false);
  const [riskType, setRiskType] = useState('');
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [workspaceId, setWorkspaceId] = useState('');
  const [isClientsAvailable, setIsClientsAvailable] = useState(false);
  const [isNoClients, setIsNoClients] = useState(false);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [isFilterNotFound, setIsFilterNotFound] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [selectedPageNumber, setSelectedPageNumber] = useState(1);
  const [listFilters, setListFilters] = useState({
    searchQuery: '',
    riskType: [],
    status: '',
    limit: 25,
    skip: 0,
    sortBy: `clientName`,
    sort: 1,
  });

  // get verified companies
  useEffect(() => {
    dispatch(getVerifiedCompanies());
    const getWorkSpaceId = async () => {
      const workspaceId = await get(`workspaceId`);
      setWorkspaceId(workspaceId);
    };
    getWorkSpaceId();
  }, [dispatch]);

  // finding verified companies
  useEffect(() => {
    if (isVerifiedCompaniesFetched && verifiedCompanies?.length) {
      const companies = verifiedCompanies?.map((company) => ({
        name: company?.name,
        value: company?.id,
        isClientsAvailable: company?.clientsAvailable,
        isOverLimit: company?.overLimitClients,
        maxLimit: company?.processingLimit,
      }));
      setCompanyId(workspaceId ?? companies[0]?.value);
      setIsSubscribed(companies?.length > 0);
      setCompanies(companies);
    }
  }, [isVerifiedCompaniesFetched, workspaceId, verifiedCompanies]);

  useEffect(() => {
    if (companyId && companies?.length) {
      const isClientsAvailable = companies?.find(
        (company) => company?.value === companyId
      )?.isClientsAvailable;
      setIsClientsAvailable(isClientsAvailable);
      setIsNoClients(!isClientsAvailable);
    }
  }, [companyId, companies]);

  useEffect(() => {
    if (companies?.length) {
      const options = companies?.map((company) => ({
        ...company,
        name: isEN ? company?.name.en : company?.name.fr,
      }));
      setCompanyOptions(options);
    }
  }, [isEN, companies]);

  useEffect(() => {
    if (
      isClientDetailsFetched &&
      clientDetails?.clients?.length === 0 &&
      summary?.clientMonitored === 0
    ) {
      setIsClientsAvailable(false);
      setIsNoClients(true);
    }
    isClientDetailsFetched && setIsTableLoading(false);
  }, [
    isClientDetailsFetched,
    isClientDeleted,
    isSummaryFetched,
    summary,
    clientDetails,
  ]);

  // get summary and client list
  useEffect(() => {
    if (companyId && isClientsAvailable) {
      dispatch(getDashboardDetails({ companyId }));
      dispatch(getAlerts({ companyId }));
      setListFilters((filters) => ({
        ...filters,
        searchQuery: '',
        riskType: [],
        status: '',
        limit: 25,
        skip: 0,
        sortBy: `clientName`,
        sort: 1,
        companyId,
      }));
    }
  }, [companyId, dispatch, clientsUnderProcess, isClientsAvailable]);

  // getting list again on filter change
  useEffect(() => {
    listFilters?.companyId && dispatch(getClientDetails(listFilters));
  }, [listFilters, dispatch]);

  useEffect(() => {
    if (isClientAdded || isClientDeleted || isRefreshed) {
      if (companyId) {
        dispatch(getDashboardDetails({ companyId }));
        dispatch(getAlerts({ companyId }));
      }
      setIsConfirmation(false);
      setFileData([]);
      setIsAddClient(false);
      setIsAdd(false);
    }
    if (
      isClientAdded ||
      isClientDeleted ||
      isRefreshed ||
      isTypeDiscovered ||
      isColumnsUpdated
    ) {
      listFilters?.companyId && dispatch(getClientDetails(listFilters));
    }
  }, [
    isClientDeleted,
    isClientAdded,
    dispatch,
    companyId,
    isTypeDiscovered,
    isRefreshed,
    listFilters,
    isColumnsUpdated,
  ]);

  useEffect(() => {
    if (isAgingFileUploaded || isClientAdded) {
      setFileData([]);
      setFile('');
      setIsAdd(false);
      setIsConfirmation(false);

      if (clientDetails?.clients?.length === 0) {
        dispatch(getVerifiedCompanies());
      }
    }
  }, [isAgingFileUploaded, isClientAdded, dispatch, clientDetails]);

  useEffect(() => {
    isAlertDeleted && companyId && dispatch(getAlerts({ companyId }));
  }, [isAlertDeleted, dispatch, companyId]);

  useEffect(() => {
    if (riskType && isRiskDiscovering) {
      setRiskType('');
    }
  }, [isRiskDiscovering, riskType]);

  useEffect(() => {
    if (isRiskDiscovered) {
      const getRiskType = async () => {
        const { riskType } = await get(`discoverPayload`);
        riskType && setIsRiskFilter(true);
        setRiskType(riskType);
      };
      getRiskType();
    }
  }, [isRiskDiscovered]);

  useEffect(() => {
    if (isClientUpdated) {
      setIsEditClient(false);
    }
  }, [isClientUpdated]);

  useEffect(() => {
    if (isFilterNotFound) {
      setIsTableLoading(true);
      setIsRiskFilter(true);
      setRiskType(`not_found`);
      setSelectedPageNumber(1);
      setListFilters((filters) => ({
        ...filters,
        status: 'Not Found',
        riskType: [],
        skip: 0,
      }));
    }
  }, [isFilterNotFound]);

  useEffect(() => {
    if (isClientAdded || isAgingFileUploaded) {
      if (companyId !== clientData?.companyId) {
        onSelection(clientData?.companyId);
      }
    }
  }, [isClientAdded, isAgingFileUploaded, companyId, clientData?.companyId]);

  const onAddHandler = () => {
    setIsAdd((prev) => !prev);
  };

  const translations = {
    uploadStep: {
      title: t(`upload_step_title`),
      manifestTitle: t(`upload_step_manifest_title`),
      manifestDescription: t(`upload_step_manifest_description`),
      maxRecordsExceeded: (maxRecords) =>
        t(`upload_step_max_exceeded_text1`) +
        t(`upload_step_max_exceeded_text2`) + 
        ` ${(maxRecords-10)} ` +
        t(`upload_step_max_exceeded_text3`),
      dropzone: {
        title: t(`upload_step_dropzone_title`),
        errorToastDescription: t(
          `upload_step_dropzone_error_toast_description`
        ),
        activeDropzoneTitle: t(`upload_step_dropzone_active_dropzone_title`),
        buttonTitle: t(`upload_step_dropzone_button_title`),
        loadingTitle: t(`upload_step_dropzone_loading_title`),
      },
      selectSheet: {
        title: t(`upload_step_select_sheet_title`),
        nextButtonTitle: t(`upload_step_select_sheet_next_button_title`),
        backButtonTitle: t(`upload_step_select_sheet_back_button_title`),
      },
    },
    selectHeaderStep: {
      title: t(`select_header_step_title`),
      nextButtonTitle: t(`select_header_step_next_button_title`),
      backButtonTitle: t(`select_header_step_back_button_title`),
    },
    matchColumnsStep: {
      title: t(`match_columns_step_title`),
      nextButtonTitle: t(`match_columns_step_next_button_title`),
      backButtonTitle: t(`match_columns_step_back_button_title`),
      userTableTitle: t(`match_columns_step_user_table_title`),
      templateTitle: t(`match_columns_step_template_title`),
      selectPlaceholder: t(`match_columns_step_select_placeholder`),
      ignoredColumnText: t(`match_columns_step_ignored_column_text`),
      subSelectPlaceholder: t(`match_columns_step_sub_select_placeholder`),
      matchDropdownTitle: t(`match_columns_step_match_dropdown_title`),
      unmatched: t(`match_columns_step_unmatched`),
      duplicateColumnWarningTitle: t(
        `match_columns_step_duplicate_column_warning_title`
      ),
      duplicateColumnWarningDescription: t(
        `match_columns_step_duplicate_column_warning_description`
      ),
    },
    validationStep: {
      title: t(`validation_step_title`),
      nextButtonTitle: t(`validation_step_next_button_title`),
      backButtonTitle: t(`validation_step_back_button_title`),
      noRowsMessage: t(`validation_step_no_rows_message`),
      noRowsMessageWhenFiltered: t(
        `validation_step_no_rows_message_when_filtered`
      ),
      discardButtonTitle: t(`validation_step_discard_button_title`),
      filterSwitchTitle: t(`validation_step_filter_switch_title`),
    },
    alerts: {
      confirmClose: {
        headerTitle: t(`confirm_close_header_title`),
        bodyText: t(`confirm_close_body_text`),
        cancelButtonTitle: t(`confirm_close_cancel_button_title`),
        exitButtonTitle: t(`confirm_close_exit_button_title`),
      },
      submitIncomplete: {
        headerTitle: t(`submit_incomplete_header_title`),
        bodyText: t(`submit_incomplete_body_text`),
        bodyTextSubmitForbidden: t(
          `submit_incomplete_body_text_submit_forbidden`
        ),
        cancelButtonTitle: t(`submit_incomplete_cancel_button_title`),
        finishButtonTitle: t(`submit_incomplete_finish_button_title`),
      },
      submitError: {
        title: t(`submit_error_title`),
        defaultMessage: t(`submit_error_default_message`),
      },
      unmatchedRequiredFields: {
        headerTitle: t(`unmatched_required_fields_header_title`),
        bodyText: t(`unmatched_required_fields_body_text`),
        listTitle: t(`unmatched_required_fields_list_title`),
        cancelButtonTitle: t(`unmatched_required_fields_cancel_button_title`),
        continueButtonTitle: t(
          `unmatched_required_fields_continue_button_title`
        ),
      },
      toast: {
        error: t(`toast_error`),
      },
    },
  };

  const fields = [
    {
      label: `${t(`clientId`)}`,
      fieldType: { type: 'input' },
      example: '#1234',
      key: `accountNumber`,
      alternateMatches: [
        'ClientID',
        'client_id',
        'Client Number',
        'Client_No',
        'Account ID',
        'account_id',
        'Customer ID',
        'customer_id',
        'Cust_ID',
        'Account Number',
        'Votre numéro de client / compte',
        'Your client / account number',
      ],
    },
    {
      label: `${t(`legal_name`)}`,
      fieldType: { type: 'input' },
      example: 'TEST NAME INC.',
      key: `clientName`,
      alternateMatches: [
        'Legal Name',
        'legal_name',
        'Company Name',
        'CompanyName',
        'Business Name',
        'business_name',
        'Company',
        'company',
        'Org Name',
        'Organization Name',
        'Nom légal du client',
        'Legal name of the client',
      ],
      validations: [
        {
          rule: 'required',
          level: 'error',
          errorMessage: `${t(`legal_name`)} ${t(`is_required`)}`,
        },
      ],
    },
    {
      label: `${t(`current`)}`,
      fieldType: { type: 'input' },
      example: '1000.00',
      key: `current`,
      alternateMatches: [
        'CurrentAmount',
        'current',
        'Current Balance',
        'Current Due',
        'Current Outstanding',
        'Curr_Amount',
        'CurrentReceivables',
        'Current',
        'Courant',
        'courant',
      ],
      validations: [
        {
          rule: 'required',
          level: 'error',
          errorMessage: `${t(`current`)} ${t(`is_required`)}`,
        },
      ],
    },
    {
      label: `${t(`period_1`)}`,
      fieldType: { type: 'input' },
      example: '1000.00',
      key: `period1`,
      alternateMatches: [
        'Period1',
        'period1',
        'period#1',
        'p1',
        'Period_1',
        'P1',
        'Period_One',
        'Period 1 Due',
        '30days_due',
        'Période_1',
        'Period_1',
        'Période 1',
        'Period 1',
        'période 1',
        'period 1',
      ],
      validations: [
        {
          rule: 'required',
          level: 'error',
          errorMessage: `${t(`period_1`)} ${t(`is_required`)}`,
        },
      ],
    },
    {
      label: `${t(`period_2`)}`,
      fieldType: { type: 'input' },
      example: '1000.00',
      key: `period2`,
      alternateMatches: [
        'Period2',
        'period2',
        'period#2',
        'p2',
        'Period_2',
        'P2',
        'Period_Two',
        'Period 2',
        'Due',
        '60days_due',
        'Période_2',
        'Period_2',
        'Période 2',
        'Period 2',
        'période 2',
        'period 2',
      ],
      validations: [
        {
          rule: 'required',
          level: 'error',
          errorMessage: `${t(`period_2`)} ${t(`is_required`)}`,
        },
      ],
    },
    {
      label: `${t(`period_3`)}`,
      fieldType: { type: 'input' },
      example: '1000.00',
      key: `period3`,
      alternateMatches: [
        'Period3',
        'period3',
        'period#3',
        'p3',
        'Period_3',
        'P3',
        'Period_Three',
        'Period 3 Due',
        '90days_due',
        'Période_3',
        'Period_3',
        'Période 3',
        'Period 3',
        'période 3',
        'period 3',
      ],
      validations: [
        {
          rule: 'required',
          level: 'error',
          errorMessage: `${t(`period_3`)} ${t(`is_required`)}`,
        },
      ],
    },
    {
      label: `${t(`period_4`)}`,
      fieldType: { type: 'input' },
      example: '1000.00',
      key: `period4`,
      alternateMatches: [
        'Period4',
        'period4',
        'period#4',
        'p4',
        'Period_4',
        'P4',
        'Period_Four',
        'Period 4 Due',
        '120days_due',
        'Période_4',
        'Period_4',
        'Période 4',
        'Period 4',
        'période 4',
        'period 4',
      ],
      validations: [
        {
          rule: 'required',
          level: 'error',
          errorMessage: `${t(`period_4`)} ${t(`is_required`)}`,
        },
      ],
    },
    {
      label: `${t(`total_due`)}`,
      fieldType: { type: 'input' },
      example: '5000.00',
      key: `totalDue`,
      alternateMatches: [
        'TotalDue',
        'total_due',
        'TotalAmountDue',
        'TotalOutstanding',
        'TotalBalance',
        'Total_Amount',
        'TotalReceivables',
        'Total_Amount_Due',
        'Total dû',
        'Total due',
      ],
      validations: [
        {
          rule: 'required',
          level: 'error',
          errorMessage: `${t(`total_due`)} ${t(`is_required`)}`,
        },
      ],
    },
    {
      label: `${t(`period_term`)}`,
      fieldType: { type: 'input' },
      example: '30',
      key: `periodTerm`,
      alternateMatches: [
        'Period Interval',
        'period_interval',
        'Interval',
        'PeriodTerm',
        'TermInterval',
        'Interval_Days',
        'DaysInterval',
        'DaysTerm',
        'Days_Interval',
        'Period_Term',
        'Période termes (30 ou 7)',
        'Period terms (30 or 7)',
      ],
    },
    {
      label: `${t(`current_credit_limit`)}`,
      fieldType: { type: 'input' },
      example: '15000.00',
      key: `currentCreditLimit`,
      alternateMatches: [
        'CurrentCreditLimit',
        'credit_limit',
        'CreditLimit',
        'Credit_Limit',
        'Credit_Limit_Current',
        'CreditAmount',
        'Credit_Amount',
        'Limite de crédit actuelle',
        'Current credit limit',
      ],
    },
    {
      label: `${t(`high_credit`)}`,
      fieldType: { type: 'input' },
      example: '15500.00',
      key: `highestCredit`,
      alternateMatches: [
        'HighCredit',
        'high credit',
        'High Credit',
        'High_Credit_Amount',
        'CreditHigh',
        'High Credit Amount',
        'MaxCredit',
        'Haut crédit',
        'High credit',
        'Haut Crédit',
      ],
    },
    {
      label: `${t(`date_of_last_sale`)}`,
      fieldType: { type: 'input' },
      example: '2024-11-22',
      key: `lastSaleDate`,
      alternateMatches: [
        'Date Last Sale',
        'date_of_last_sale',
        'Last sale Date',
        'Last_Sale_Date',
        'SaleDate',
        'DateLastTransaction',
        'DateOfLastTransaction',
        'Date de dernière vente',
        'Date of last sale',
        'Dernière vente',
      ],
    },
    {
      label: `${t(`account_opening_date`)}`,
      fieldType: { type: 'input' },
      example: '1999-12-21',
      key: `accountOpeningDate`,
      alternateMatches: [
        'Account Opening Date',
        'account_opening_date',
        'OpeningDate',
        'DateOpened',
        'Date_Opened',
        'AccountStartDate',
        'AccountStartDate',
        "Date d'ouverture du compte",
        'Account opening date',
        'Ouverture du compte',
      ],
    },
    {
      label: `${t(`average_payment`)}`,
      fieldType: { type: 'input' },
      example: '19',
      key: `averagePayment`,
      alternateMatches: [
        'Average Payment DSO',
        'average_payment_dso',
        'DSO',
        'AverageDaysSalesOutstanding',
        'Avg Payment',
        'Avg DSO',
        'DaysSalesOutstanding',
        'Average DSO',
        'Moyenne de paiement (DSO)',
        'Average payment (DSO)',
        'Moyenne de paiement',
      ],
    },
    {
      label: `${t(`nsf`)}`,
      fieldType: { type: 'input' },
      example: '2',
      key: `nsf`,
      alternateMatches: [
        'NSF',
        'nsf',
        'Non Sufficient Funds',
        'Non_Sufficient_Funds',
        'NSF_Count',
        'NSF_Counter',
      ],
    },
    {
      label: `${t(`comment`)}`,
      fieldType: { type: 'input' },
      example: '',
      key: `comment`,
      alternateMatches: [
        'Comments',
        'Comment',
        'Notes',
        'ClientComments',
        'Remarks',
        'Remarks_Comments',
        'Feedback',
        'Commentaire',
        'commentaire',
      ],
    },
    {
      label: `${t(`trade_name_other_name`)}`,
      fieldType: { type: 'input' },
      example: 'TEST 1234, TEST2, TEST3',
      key: `otherNames`,
      alternateMatches: [
        'Trade Name',
        'Other Name',
        'trade_name',
        'autre nom',
        'Alternate Name',
        'Trade_AlternateName',
        'Raison sociale',
        'Raison sociale / Autre nom',
        'Trade Name / Other Name',
      ],
    },
    {
      label: `${t(`civic_number`)}`,
      fieldType: { type: 'input' },
      example: '123',
      key: `civicNumber`,
      alternateMatches: [
        'Civic number',
        'civic_number',
        'AddressNumber',
        'Number',
        'Civic_No',
        'Street Number',
        'Numéro civique',
      ],
    },
    {
      label: `${t(`street`)}`,
      fieldType: { type: 'input' },
      example: 'Richmond',
      key: `street`,
      alternateMatches: [
        'Street',
        'street',
        'Address',
        'Street Address',
        'Street Name',
        'Rue',
      ],
    },
    {
      label: `${t(`suite`)}`,
      fieldType: { type: 'input' },
      example: 'Local 201',
      key: `suite`,
      alternateMatches: [
        'Suite',
        'suite',
        'Apt',
        'Apartment',
        'Unit',
        'Suite_Number',
        'Apt_Number',
      ],
    },
    {
      label: `${t(`city`)}`,
      fieldType: { type: 'input' },
      example: 'Toronto',
      key: `city`,
      alternateMatches: [
        'City',
        'city',
        'City Name',
        'Municipality',
        'Locality',
        'ville',
        'Ville',
      ],
    },
    {
      label: `${t(`province`)}`,
      fieldType: { type: 'input' },
      example: 'ON',
      key: `province`,
      alternateMatches: [
        'Province',
        'State',
        'province_state',
        'province/state',
        'Prov',
        'Prov_State',
        'state',
        'State Province',
        'Province / State',
        'province',
      ],
      validations: [
        {
          rule: 'required',
          level: 'error',
          errorMessage: `${t(`province`)} ${t(`is_required`)}`,
        },
      ],
    },
    {
      label: `${t(`postal_code`)}`,
      fieldType: { type: 'input' },
      example: 'A1A1A1',
      key: `postalCode`,
      alternateMatches: [
        'Postal Code',
        'ZipCode',
        'code postal',
        'zip_code',
        'Postal / Zip',
        'postal code',
        'Zip Postal',
        'Code Postal / Zip Code',
        'Postal Code / Zip Code',
      ],
    },
    {
      label: `${t(`country`)}`,
      fieldType: { type: 'input' },
      example: 'Canada',
      key: `country`,
      alternateMatches: [
        'Country',
        'country',
        'Country Name',
        'Country_Code',
        'Nation',
        'Country_Name',
        'Pays',
        'pays',
      ],
    },
    {
      label: `${t(`phone`)}`,
      fieldType: { type: 'input' },
      example: '(123)456-7890',
      key: `phoneNumber`,
      alternateMatches: [
        'PhoneNumber',
        'phone_number',
        'ContactNumber',
        'Phone',
        'Telephone',
        'telephone',
        'Phone_No',
        'Contact_No',
        'Numéro de téléphone',
        'Phone number',
        'tel',
        'téléphone',
      ],
    },
    {
      label: `${t(`terms`)}`,
      fieldType: { type: 'input' },
      example: 'Net 30',
      key: `terms`,
      alternateMatches: [
        'PaymentTerms',
        'terms',
        'TermsOfPayment',
        'Terms',
        'Conditions',
        'Payment_Conditions',
        'Termes',
        'Payment terms',
        'Terme de paiement',
      ],
    },
    {
      label: `${t(`currency`)}`,
      fieldType: { type: 'input' },
      example: 'CAD',
      key: `currency`,
      alternateMatches: [
        'Currency',
        'currency',
        'CurrencyType',
        'Currency_Code',
        'Currency_Used',
        'Currency_Amount',
        'CurrencyType',
        'Devise',
        'devise',
      ],
    },
    {
      label: `${t(`neq`)}`,
      fieldType: { type: 'input' },
      example: '123456789',
      key: `neq`,
      alternateMatches: [
        'NEQ',
        'neq',
        'Business Number',
        'Enterprise Number',
        'National Enterprise Number',
        'enterprise number',
        'NEQ / Numéro de corporation',
        'NEQ / Corporation Number',
        'Corporation Number',
        'corporation number',
        'registry number',
        "numéro d'entreprise",
        'numero de corporation',
        'Numéro de corporation',
      ],
    },
  ];

  const onSubmit = (data, file) => {
    setFileData(data?.validData);
    setFile(file);
    setIsConfirmation(true);
  };

  const onCancelUpload = () => {
    setIsConfirmation(false);
    setFileData([]);
    setFile('');
  };

  const onUploadAgingFile = () => {
    const payload = {
      companyId,
      ...clientData,
      type: `Aging`,
      clients: fileData,
    };
    const modifiedData = convertToSquareBracketNotation(payload);
    modifiedData.client_file = file;
    let formData = new FormData();
    for (let key in modifiedData) {
      formData.append(
        key,
        key !== `upto[month]` && isEmpty(modifiedData[key])
          ? ''
          : modifiedData[key]
      );
    }
    dispatch(uploadAgingFile(formData));
  };

  const onClose = () => {
    setIsUploadAging(false);
  };

  const onCancelAddClient = () => {
    setIsAddClient(false);
  };

  const onAddClient = () => {
    addClientRef?.current?.validateClientForm();
  };

  const onToastCallback = () => {
    dispatch(resetHealthMonitoringStore());
  };

  const onRemoveRiskFilter = () => {
    setIsRiskFilter(false);
    setRiskType('');
    setIsFilterNotFound(false);
    setListFilters((filters) => ({ ...filters, riskType: [], status: '' }));
    setSelectedPageNumber(1);
  };

  const onGetClientInformation = (clientId) => {
    clientId &&
      companyId &&
      dispatch(getClientInformation({ clientId, companyId }));
    setIsEditClient(true);
  };

  const onCancelUpdateClient = () => {
    setIsEditClient(false);
  };

  const rowHook = (row, addError) => {
    //cleaning required value
    row.current = cleanMoney(row.current);
    row.period1 = cleanMoney(row.period1);
    row.period2 = cleanMoney(row.period2);
    row.period3 = cleanMoney(row.period3);
    row.period4 = cleanMoney(row.period4);
    row.totalDue = cleanMoney(row.totalDue);

    //cleaning & triming
    row.postalCode = (row.postalCode || '').replace(/[^A-Za-z0-9]/g, '');
    row.clientName = (row.clientName || '').trim();

    //Validation if not empty and then cleaning value
    if (!isEmpty(row.currentCreditLimit)) {
      row.currentCreditLimit = cleanMoney(row.currentCreditLimit);
    } else {
      addError('currentCreditLimit', {
        message: t(`current_credit_limit`) + " " + t(`is_recommended`),
        level: 'warning',
      });
    }

    if (!isEmpty(row.highestCredit)) {
      row.highestCredit = cleanMoney(row.highestCredit);
    } else {
      addError('highestCredit', {
        message: t(`high_credit`) + " " + t(`is_recommended`),
        level: 'warning',
      });
    }

    if (isValidDate(row.lastSaleDate)) {
      row.lastSaleDate = formatDate(row.lastSaleDate);
    } else if (isEmpty(row.lastSaleDate)) {
      addError('lastSaleDate', {
        message: t(`date_of_last_sale`) + " " + t(`is_recommended`),
        level: 'warning',
      });
    } else {
      addError('lastSaleDate', {
        message: t(`error_date_invalid`),
        level: 'error',
      });
    }

    if (isValidDate(row.accountOpeningDate)) {
      row.accountOpeningDate = formatDate(row.accountOpeningDate);
    } else if (isEmpty(row.accountOpeningDate)) {
      addError('accountOpeningDate', {
        message: t(`account_opening_date`) + " " + t(`is_recommended`),
        level: 'warning',
      });
    } else {
      addError('accountOpeningDate', {
        message: t(`error_date_invalid`),
        level: 'error',
      });
    }

    if (!isEmpty(row.averagePayment)) {
      row.averagePayment = cleanMoney(row.averagePayment);
    } else {
      addError('averagePayment', {
        message: t(`average_payment`) + " " + t(`is_recommended`),
        level: 'warning',
      });
    }

    if (!isEmpty(row.nsf)) {
      row.nsf = cleanMoney(row.nsf);
    } else {
      addError('nsf', {
        message: t(`nsf`) + " " + t(`is_recommended`),
        level: 'warning',
      });
    }

    //Normalise Value
    row.currency = normalizeCurrency(row.currency);
    row.province = normalizeProvince(row.province);
    if (String(row?.periodTerm) !== '30' && String(row?.periodTerm) !== '7') {
      row.periodTerm = 30;
    }

    return row;
  };

  const onSelection = async (option) => {
    setCompanyId(option);
    await set(`workspaceId`, option);
    setIsRiskFilter(false);
    setSelectedPageNumber(1);
  };

  const onFilterChange = useCallback((filterName, value) => {
    setListFilters((filters) => ({ ...filters, [filterName]: value }));
    setIsTableLoading(true);
  }, []);

  return (
    <>
      {apiMessage && (
        <ToastMessage
          message={apiMessage}
          action={onToastCallback}
          type={
            isAlertDeleted ||
            isRiskDiscovered ||
            isTypeDiscovered ||
            isClientDeleted ||
            isClientAdded ||
            isClientUpdated ||
            isReportOrdered ||
            isAgingFileUploaded ||
            isRefreshed
              ? `success`
              : isColumnUpdateError ||
                isDiscoverError ||
                isClientInformationFetchError ||
                isReportOrderError ||
                isAgingFileUploadError ||
                isClientDetailsFetchError
              ? `error`
              : null
          }
        />
      )}
      <div className='credit_risk_header'>
        <div className='title_actions'>
          <div className='title'>
            {`${t(`client_credit_risk_dashboard`)}`}
            {isSummaryFetched && (
              <>
                <span className='package_name'>{summary?.package?.title}</span>
                <span className='package_validity'>
                  {`${t(`valid_till`)} `}
                  {new Date(summary?.package?.expiresAt)?.toLocaleDateString(
                    'en-CA',
                    { year: 'numeric', month: '2-digit', day: '2-digit' }
                  )}
                </span>
              </>
            )}
          </div>
          <div className='actions'>
            <Select
              options={companyOptions}
              text={`${t(`workspace`)}`}
              onBlur={() => {}}
              isError={false}
              onOptionCallback={() => {}}
              onOptionSelection={(option) => onSelection(option)}
              errorMessage={''}
              isShowValue={true}
              value={companyId}
            />
            <Button
              type='primary'
              text={`${t(`add_client_upload_sheet`)}`}
              onClickHandler={() => setIsAdd(true)}
            />
          </div>
        </div>
      </div>
      {isVerifiedCompaniesFetching && (
        <Container>
          <div className='no_credit_risk_clients'>
            <div className='credit_risk_summary_wrapper'>
              <div className='health_heading'>{`${t(`fetching_clients`)}`}</div>
              <div className='btns_wrapper'>
                <Button
                  type={`secondary`}
                  text={``}
                  onClickHandler={onAddHandler}
                  isLoading={true}
                />
              </div>
            </div>
          </div>
        </Container>
      )}
      {isVerifiedCompaniesFetched && !isClientsAvailable && isNoClients && (
        <Container>
          <div className='no_credit_risk_clients'>
            <div className='credit_risk_summary_wrapper'>
              <HealthMonitoringIcon />
              <div className='health_heading'>{`${t(`no_monitoring`)}`}</div>
              <div className='health_sub_heading'>{`${t(
                `begin_monitoring`
              )}`}</div>
              <div className='btns_wrapper'>
                <Button
                  type='primary'
                  text={`${t(`add_client_upload_file`)}`}
                  onClickHandler={onAddHandler}
                  isLoading={false}
                />
              </div>
            </div>
          </div>
        </Container>
      )}
      {isVerifiedCompaniesFetched && isClientsAvailable && companyId && (
        <div className='credit_risk_wrapper'>
          <div className='health_dashboard_wrapper'>
            {isSubscribed ? (
              <Container>
                <MonitoringSummary
                  companyId={companyId}
                  isEN={isEN}
                  onGetClientInformation={onGetClientInformation}
                  isRiskFilter={isRiskFilter}
                  isRiskDiscovered={isRiskDiscovered}
                  discoveredRiskType={riskType}
                  summary={summary}
                  isOverLimit={
                    isSummaryFetching
                      ? 0
                      : summary?.package?.clientsOverLimit > 0
                  }
                  isSummaryFetching={!isAgingFileUploaded && isSummaryFetching}
                  isAlertsFetching={isAlertsFetching}
                  isAlertsFetched={isAlertsFetched}
                  alerts={alerts}
                  isAlertDeleted={isAlertDeleted}
                  isAgingFileUploaded={isAgingFileUploaded}
                  isFilterNotFound={isFilterNotFound}
                  setIsFilterNotFound={setIsFilterNotFound}
                  isSummaryFetched={isSummaryFetched}
                />
                <ClientList
                  companyId={companyId}
                  onFilterChange={onFilterChange}
                  isTableLoading={isTableLoading}
                  setIsTableLoading={setIsTableLoading}
                  setListFilters={setListFilters}
                  listFilters={listFilters}
                  selectedPageNumber={selectedPageNumber}
                  setSelectedPageNumber={setSelectedPageNumber}
                  isRefetchClientList={
                    isClientUpdated ||
                    isColumnsUpdated ||
                    isTypeDiscovered ||
                    isClientAdded ||
                    isClientDeleted ||
                    isRefreshed
                  }
                  clientsUnderProcess={clientsUnderProcess}
                  onGetClientInformation={onGetClientInformation}
                  isLoading={isClientInformationFetching}
                  isRiskFilter={isRiskFilter}
                  setIsRiskFilter={setIsRiskFilter}
                  onRemoveRiskFilter={onRemoveRiskFilter}
                  isRiskDiscovered={isRiskDiscovered}
                  isTypeDiscovered={isTypeDiscovered}
                  isClientDetailsFetching={isClientDetailsFetching}
                  isClientDetailsFetched={isClientDetailsFetched}
                  isClientDeleted={isClientDeleted}
                  isClientUpdated={isClientUpdated}
                  isRiskDiscovering={isRiskDiscovering}
                  riskType={riskType}
                  setRiskType={setRiskType}
                  clientDetails={clientDetails}
                  isClientsAvailable={isClientsAvailable}
                  isSummaryFetching={isSummaryFetching}
                  isRefreshing={isRefreshing}
                  isAgingFileUploaded={isAgingFileUploaded}
                  isFilterNotFound={isFilterNotFound}
                  isOverLimit={
                    isSummaryFetching
                      ? 0
                      : summary?.package?.clientsOverLimit > 0
                  }
                />
              </Container>
            ) : (
              <Subscription />
            )}
          </div>
        </div>
      )}
      {isUploadAging && (
        <ReactSpreadsheetImport
          isOpen={isUploadAging}
          isNavigationEnabled={true}
          autoMapHeaders={true}
          allowInvalidSubmit={false}
          autoMapDistance={1.5}
          onClose={onClose}
          fields={fields}
          onSubmit={onSubmit}
          rowHook={rowHook}
          translations={translations}
          maxRecords={clientData?.maxLimit+10}
          //https://github.com/UgnisSoftware/react-spreadsheet-import/blob/master/src/theme.ts#L93
          customTheme={{
            components: {
              UploadStep: {
                baseStyle: {
                  heading: {
                    fontSize: '3xl',
                  },
                  title: {
                    fontSize: '2xl',
                    mb: '1rem',
                  },
                  subtitle: {
                    fontSize: 'xl',
                  },
                  dropzoneText: {
                    fontSize: '2xl',
                  },
                  tableWrapper: {
                    h: '150px',
                  },
                  dropzoneButton: {
                    bg: '#356eb1',
                  },
                },
              },
            },
            styles: {
              global: {
                ':root': {
                  fontSize: '16px',
                },
              },
            },
          }}
        />
      )}
      <SideDrawer
        from={`generic_drawer credit_risk credit_risk_sidedrawer_wrapper ${
          isAddClient ? `credit_risk_add_client` : null
        }`}
        isVisible={isAdd}
        heading={`${t(`add_client_upload_file`)}`}
        content={
          isAddClient ? (
            <AddClient
              ref={addClientRef}
              clientData={clientData}
              isEdit={false}
              companyId={companyId}
            />
          ) : (
            <UploadAgingAddClient
              setIsAddClient={setIsAddClient}
              setClientData={setClientData}
              clientData={clientData}
              companyErrorMessage={companyErrorMessage}
              setCompanyErrorMessage={setCompanyErrorMessage}
              setIsUploadAging={setIsUploadAging}
              isConfirmation={isConfirmation}
              fileData={fileData}
              onCancelUpload={onCancelUpload}
              onUploadAgingFile={onUploadAgingFile}
              isAgingFileUploading={isAgingFileUploading}
              companyOptions={companyOptions}
              isOverLimit={clientData?.isOverLimit}
              isEN={isEN}
            />
          )
        }
        isHideFooter={isAddClient ? false : true}
        isClosable={true}
        onCloseSidebar={onAddHandler}
        isNavigation={isAddClient}
        navigation={navigation}
        onBack={() => setIsAddClient(false)}
        footer={
          <>
            <Button
              type='quaternary'
              text={`${t(`cancel`)}`}
              onClickHandler={onCancelAddClient}
            />
            <Button
              type='primary'
              text={`${t(`add_client`)}`}
              onClickHandler={onAddClient}
              isLoading={isClientAdding}
            />
          </>
        }
      />
      {/* // update client */}
      <SideDrawer
        from='generic_drawer credit_risk_add_client'
        isVisible={isEditClient && isClientInformationFetched}
        heading={`${t(`update_client`)}`}
        content={
          <AddClient
            isEdit={true}
            clientInformation={clientInformation?.companyDetails?.[0]}
            ref={addClientRef}
            companyId={companyId}
          />
        }
        footer={
          <>
            <Button
              type='quaternary'
              text={`${t(`cancel`)}`}
              onClickHandler={onCancelUpdateClient}
            />
            <Button
              type='primary'
              text={`${t(`update_client`)}`}
              onClickHandler={onAddClient}
              isLoading={isClientUpdating}
            />
          </>
        }
      />
    </>
  );
};

export default CreditRiskManagement;
