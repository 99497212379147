import React, { useEffect, useState, useRef } from 'react';
import Input from '../Input';
import { weeks, months, years } from './DatePickerOptions';
import {
  AiOutlineLeft,
  AiOutlineRight,
  AiFillCaretUp,
  AiFillCaretDown,
} from 'react-icons/ai';

const DatePicker = ({
  text,
  isOnTop,
  isHidePreviousDate,
  onDateChange,
  isShowValue,
  value,
  isError,
  isMandatory,
  isRecommended,
}) => {
  const today = new Date();
  const containerRef = useRef();

  const [isDatePicker, setIsDatePicker] = useState(false);
  const [weekDays, setWeekDays] = useState('');
  const [emptyDays, setEmptyDays] = useState('');
  const [monthCount, setMonthCount] = useState(new Date().getMonth() + 1);
  const [yearCount, setYearCount] = useState(new Date().getFullYear());
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [isShowYears, setIsShowYears] = useState(false);
  const [displayYears, setDisplayYears] = useState(years);
  const [isDisablePreviousDate, setIsDisablePreviousDate] = useState(false);

  // setting default dates
  useEffect(() => {
    if (isShowValue && value) {
      setSelectedDate(value);
      setIsDateSelected(true);
    }
  }, [isShowValue, value]);

  // hiding previous dates
  useEffect(() => {
    if (isHidePreviousDate) {
      const yearsToShow = years?.filter(
        (year) => year >= new Date().getFullYear()
      );
      setDisplayYears(yearsToShow);
    }
  }, [isHidePreviousDate]);

  // disable previus dates and months
  useEffect(() => {
    if (isHidePreviousDate) {
      const month = new Date().getMonth() + 1;
      const year = new Date().getFullYear();
      if (year === yearCount && monthCount === month) {
        setIsDisablePreviousDate(true);
      } else {
        setIsDisablePreviousDate(false);
      }
    }
  }, [isHidePreviousDate, yearCount, monthCount]);

  const clickedOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsDatePicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener(`click`, clickedOutside, true);
    return () => {
      document.removeEventListener(`click`, clickedOutside, true);
    };
  });

  const onDatePickerDisplay = () => {
    setIsDatePicker(!isDatePicker);
  };

  const getNumberOfWeeks = (month, year) => {
    const startDate = new Date(year, month - 1, 1);
    const endDate = new Date(year, month, 0);
    const timeDifference = Math.abs(startDate - endDate);
    const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    const prefixDays = startDate?.getDay();
    setWeekDays(dayDifference + 1);
    setEmptyDays(prefixDays);
  };

  useEffect(() => {
    getNumberOfWeeks(monthCount, yearCount);
  }, [monthCount, yearCount]);

  const onNextMonth = () => {
    if (monthCount < 12) {
      setMonthCount((month) => month + 1);
    }
    if (monthCount >= 12) {
      setYearCount((year) => year + 1);
      setMonthCount(1);
    }
  };

  const onPrevMonth = () => {
    if (monthCount <= 1) {
      setMonthCount(12);
      setYearCount((year) => year - 1);
    } else {
      setMonthCount((month) => month - 1);
    }
  };

  const onDateSeelction = (day) => {
    const date = new Date(yearCount, monthCount - 1, day);
    setSelectedDate(date);
    setIsDateSelected(true);
    setIsDatePicker(false);
    onDateChange(date);
  };

  const onYearHandler = () => {
    setIsShowYears((isYear) => !isYear);
  };

  const onYearSelection = (year) => {
    setYearCount(year);
    setIsShowYears(false);
  };

  return (
    <div className='datepicker_wrapper'>
      <Input
        type='datepicker'
        text={text}
        onClick={onDatePickerDisplay}
        onChange={(e) => e.preventDefault()}
        value={
          isShowValue && value
            ? value?.toLocaleDateString()
            : selectedDate && selectedDate?.toLocaleDateString()
        }
        onBlur={() => {}}
        isError={isError}
        isMandatory={isMandatory}
        isRecommended={isRecommended}
        
      />
      <div
        style={{ top: isOnTop ? '-36.5rem' : '5.5rem' }}
        ref={containerRef}
        className={`datepicker_box ${isDatePicker ? `show_datepicker` : ``}`}
      >
        {isDatePicker && (
          <>
            <div className='month_year_switcher'>
              <div className='month_year' onClick={onYearHandler}>
                <div className='month'>{months?.[monthCount - 1]?.name}</div>
                <div className='year'>{yearCount}</div>
                <span className='datepicker_icon'>
                  {isShowYears ? <AiFillCaretDown /> : <AiFillCaretUp />}
                </span>
              </div>
              <div className='switcher'>
                <div
                  className={`switcher_icon_wrapper ${
                    isDisablePreviousDate ? `switcher_disabled` : null
                  }`}
                  onClick={onPrevMonth}
                >
                  {!isShowYears && <AiOutlineLeft className='switcher_icon' />}
                </div>
                <div className='switcher_icon_wrapper' onClick={onNextMonth}>
                  {!isShowYears && <AiOutlineRight className='switcher_icon' />}
                </div>
              </div>
            </div>
            <div className='weeks_wrapper'>
              {weeks.map((weekDay) => {
                return (
                  <React.Fragment key={weekDay.index}>
                    <div className='week_box'>{weekDay.name}</div>
                  </React.Fragment>
                );
              })}
            </div>
            <div className='days_wrapper'>
              {Array.from({ length: emptyDays }).map((_, index) => {
                return <div className='day_box' key={index}></div>;
              })}
              {Array.from({ length: weekDays }).map((_, index) => {
                return (
                  <div
                    className={`day_box ${
                      today?.getFullYear() === yearCount &&
                      today?.getMonth() + 1 === monthCount &&
                      today?.getDate() === index + 1
                        ? `day_today`
                        : ``
                    } ${
                      isDateSelected &&
                      selectedDate?.getFullYear() === yearCount &&
                      selectedDate?.getMonth() + 1 === monthCount &&
                      selectedDate?.getDate() === index + 1
                        ? `day_selected`
                        : ``
                    } 
                    ${
                      isHidePreviousDate &&
                      today?.getFullYear() === yearCount &&
                      today?.getMonth() + 1 === monthCount &&
                      today?.getDate() > index + 1
                        ? `hide_previous_day`
                        : null
                    }
                    `}
                    key={index}
                    onClick={() => onDateSeelction(index + 1)}
                  >
                    {index + 1}
                  </div>
                );
              })}
            </div>
            <div className={`years_wrapper ${isShowYears ? `show_years` : ``}`}>
              {displayYears.map((year) => {
                return (
                  <div
                    className={`year_box ${
                      yearCount === year ? `year_selected` : ``
                    }`}
                    key={year}
                    onClick={() => onYearSelection(year)}
                  >
                    {year}
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DatePicker;
